export default class Nav {
    constructor() {
        this.dom = $('html')
        this.trigger = $('[data-nav-trigger]')

        this.updateNavLinks()

        this.trigger.on('click', ()=> {
            this.toggle()
        })

        if(swup == undefined) return 

            swup.hooks.on('animation:out:start', () => this.hide())
            swup.hooks.on('content:replace', () => this.updateNavLinks())
    }

    toggle() {
        this.dom.toggleClass('nav-is-active')
    }

    show() {
        this.dom.addClass('nav-is-active')
    }

    hide() {
        this.dom.removeClass('nav-is-active')
    }

    updateNavLinks() {
        let links = $('.nav a:not(.btn), header a:not(.btn)')

        links.each((i, ele) => {
            let href = ele.href.replace(`${window.location.protocol}//${window.location.host}`, '')
            
            window.location.pathname == href ? $(ele).addClass('current') : $(ele).removeClass('current')
            
        })
        
    }
}
// Nav

// nav = (function($) {
  
//   var opts = {
//     $trigger: $('[data-nav-trigger]'),
//     $nav: $('[data-nav-panel]'),
//     $body: $('body'),
//     active: 'active'
//   };
  
//   var toggle = function() {
    
//     // https://stackoverflow.com/questions/48915795/saving-scroll-position-while-using-position-fixed-to-prevent-body-scrolling-on
//     // https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
//     // https://pqina.nl/blog/how-to-prevent-scrolling-the-page-on-ios-safari/
    
//     opts.$trigger.on('click', function(event) {
        
      
//       var $this = $(this);
      
//       /*
//       $("selector").click(function() { 
//         return (this.tog = !this.tog) ? a() : b();
//       });
//       */
      
//       /*
//       if (isFunctionA) {
//         functionA();
//       } else {
//         functionB();
//       }
//       isFunctionA = !isFunctionA; // Toggle the boolean state
//       */
      
//       if ($this.hasClass(opts.active)) {
//         close();
//       } else {
//         open();
//       }
      
//       event.preventDefault();
//     });
      
//   };
  
//   var open = function() {
    
//     var scrollPos = $(window).scrollTop();
    
//     // Pin/Freeze header
//     $('.header').headroom('pin').headroom('freeze');
    
//     // Add active class
//     opts.$trigger.addClass(opts.active);
//     opts.$nav.addClass(opts.active);
    
//     // Add no-scroll class
//     opts.$body.addClass('no-scroll');
    
//     // Add top position
//     opts.$body.css('top', '-' + scrollPos + 'px');
//     // $('body').css('top', `-${scrollPos}px`);
    
//     // iOS 15 fix
//     document.addEventListener('pointermove', function(event) {
//       event.preventDefault();
//     });
    
//   };
  
//   var close = function() {
    
//     var scrollPos = parseInt($('body').css('top'), 10);
//     // var scrollPos = parseInt(opts.$body.css('top') || '0');
    
//     // Remove active class
//     opts.$trigger.removeClass(opts.active);
//     opts.$nav.removeClass(opts.active);
    
//     // Remove no-scroll class
//     opts.$body.removeClass('no-scroll');
    
//     // Remove top position
//     opts.$body.css('top', '0');
    
//     // Maintain scroll position
//     // $(window).scrollTop(scrollPos *= -1);
//     window.scrollTo({
//       top: parseInt(scrollPos || '0') * -1,
//       behavior: 'instant'
//     });
    
//     // Unfreeze/Pin header once the browser has (theoretically) finished scrolling
//     setTimeout(() => {
//       $('.header').headroom('unfreeze').headroom('pin');
//     }, 500);
    
//     /*
//     // Unfreeze/Pin header once the browser has finished scrolling
//     scrollTo(parseInt(scrollPos || '0') * -1, 'instant', function() {
//       // Unfreeze/Pin header
//       $('.header').headroom('pin').headroom('unfreeze');
//     });
//     */
    
//     // iOS 15 fix
//     document.removeEventListener('pointermove', function(event) {
//       event.preventDefault();
//     });
    
//   };
  
//   // Init
//   var init = function() {
    
//     toggle();
    
//   };
  
//   // Public
//   return {
//     init: init
//   };

// })(jQuery);