import gsap from '../vendor/plugins/gsap.js'
import {ScrollTrigger} from '../vendor/plugins/ScrollTrigger.js'

gsap.registerPlugin(ScrollTrigger)

export default class AnchorList {
    constructor() {
        this.init()

        if(swup == undefined) return 

            swup.hooks.on('visit:end', () => this.init() )
    }

    init() {
        this.section = $('.anchor-list')

        this.section.each((i, element) => {
            let navItems = $(element).find('.anchor-list__nav li')
            let items = $(element).find('.anchor-list__item')
            let offset = 100
            let headerHeight = ()=> $('.header').innerHeight() + offset

            items.each((ii, ele) => {
                let scrollAnim = ScrollTrigger.create({
                    // markers: true,
                    trigger: ele,
                    invalidateOnRefresh: true,
                    start: ()=> `top top+=${headerHeight()}`,
                    end: ()=> `bottom top+=${headerHeight()}`,
                    onEnter: () => this.toggleActiveClass(navItems, ii),
                    onEnterBack: () => this.toggleActiveClass(navItems, ii) 
                })

                // kill scrollTrigger
                if(swup) swup.hooks.on('visit:start', () => scrollAnim.kill() )
            })
        })
        
    }

    toggleActiveClass(navItems, index) {
        $(navItems[index]).addClass('is-active')
        navItems.not(navItems[index]).removeClass('is-active')
    }
}