import gsap from '../vendor/plugins/gsap.js'
import Swup from '../vendor/plugins/swup.js'

export default class Transition {
    constructor() {
        this.instance = new Swup({
            containers: ["#swup"],
            animateHistoryBrowsing: true,
        })

        this.ScrollTriggers = []

        window.swup = this.instance

        this.swupEventHandler()
    }

    swupEventHandler() {
        /* ======================================== *\
        /* HANDLE OUT ANIMATION
        /* ======================================== */
        this.instance.hooks.replace('animation:out:await', async (data) => {
            await this.animOut(data)
        })

        /* ======================================== *\
        /* HANDLE IN ANIMATION
        /* ======================================== */
        this.instance.hooks.replace('animation:in:await', async (data) => {
            await this.animIn(data)
        })

        /* ======================================== *\
        /* 	DESTROY SCROLLTRIGGERS
        /* ======================================== */
        this.instance.hooks.on('visit:start', () => {
            console.log('triggers', this.ScrollTriggers)
        })
    }

    animOut(data) {
        let tl = gsap.timeline({})

        tl.to('#swup', { duration: 0.75, autoAlpha: 0 })

        return tl
    }

    animIn(data) {
        let tl = gsap.timeline({})

        tl.fromTo('#swup',
            { opacity: 0 }, 
            { opacity: 1, duration: 0.75,}
        )

        return tl
    }

    registerScrollTrigger(ScrollTrigger) {
        if(Array.isArray(ScrollTrigger)) {
            ScrollTrigger.forEach((trigger)=> {
                this.ScrollTriggers.push(trigger)
            })
        } else {
            this.ScrollTriggers.push(ScrollTrigger)
        }
    }
}